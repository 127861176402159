import React, { useState } from "react";

import "./About.scss";
import Modal from "../../components/modal/Modal";

// Images
import Us from "../../assets/images/refinery.jpg";

// Logos
import Formatic from "../../assets/images/4Matic.png";
import Armaturen from "../../assets/images/ARMATUREN.jpg";
import StreamFlo from "../../assets/images/SF-logo.png";
import Lsm from "../../assets/images/LSM-logo.png";
import Omc from "../../assets/images/OMC-ITALY.png";

const MARCAS_ITEMS = [
  {
    id: 1,
    items: [
      { code: "A1", name: "Válvulas de Control tipo Globo y Mariposa" },
      { code: "A2", name: "Válvulas de Corte Automáticas y Manuales" },
      { code: "A3", name: "Válvulas de Seguridad y Alivio" },
      { code: "A4", name: "Reguladores de Presión y Temperatura" },
      { code: "A5", name: "Trampas de Vapor y Condensados" },
      { code: "A6", name: "Sistemas de Ingeniería" },
    ],
    url: "https://www.ari-armaturen.us/",
  },
  {
    id: 2,
    items: [
      {
        code: "F1",
        name: "Válvulas de Bola montadas sobre Muñon Clases: 150 -2500#",
      },
      { code: "F2", name: "Válvulas de Bola Flotante" },
    ],
    url: "https://www.streamflo.com/en/",
  },
  {
    id: 3,
    items: [
      { code: "O1", name: "Válvulas de Control tipo Globo Clases: 150-2500#" },
      { code: "O2", name: "Válvulas de Control Sanitarias" },
      { code: "O3", name: "Reguladores Presión" },
      { code: "O4", name: "Posicionadores y Accesorios" },
      { code: "O5", name: "Instrumentación Neumática" },
    ],
    url: "https://www.omcamericas.com/",
  },
  {
    id: 4,
    items: [
      {
        code: "41",
        name: "Válvulas de Mariposa Concentricas, Alto Rendimiento y Triple Excentricidad",
      },
      { code: "42", name: "Válvulas de Bola de 2, 3 y 4 vías" },
      { code: "43", name: "Válvulas para manejo de Sistemas de agua potable" },
      {
        code: "44",
        name: "Accesorios -Posicionadores, Interruptores de Limite, Solenoides",
      },
    ],
    url: "https://www.4matic.us/",
  },
  {
    id: 5,
    items: [{ code: "L1", name: "Bombas Peristálticas" }],
    url: "https://lsm.dk/en/",
  },
];

function About() {
  let [open, setOpen] = useState(false);
  let [modalTitle, changeTitle] = useState("");
  let [modalItem, changeItem] = useState(null);
  let [modalUrl, changeUrl] = useState("");

  const handleClick = (e, marca, id) => {
    e.preventDefault();
    changeTitle(marca);
    const actualMarca = MARCAS_ITEMS.find((m) => Number(m.id) === Number(id));
    changeItem(actualMarca);
    changeUrl(actualMarca.url);
    onOpenModal();
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="info-about">
        <div className="us">
          <img src={Us} alt="nosotros" />
          <div className="us-info">
            <h2>
              Moreval es una empresa progresiva, preparada para satisfacer&nbsp;
              <br className="space-special" />
              las demandas de la industria que siempre están en evolución.&nbsp;
              <br />
              Puedes estar seguro de que nuestros expertos&nbsp;
              <br className="space-special" />
              proporcionaran la mejor válvula para tu aplicación.
            </h2>
            <h2>
              Somos tu soporte en válvulas.
              <br />
              Todos los días renovamos nuestro compromiso de ofrecerte&nbsp;
              <br className="space-special" />
              el mejor servicio, basándonos en la experiencia,
              confiabilidad,&nbsp;
              <br className="space-special" />
              productos de alta calidad y respuesta rápida.
            </h2>
            <h2>
              Nuestro objetivo es tener clientes satisfechos&nbsp;
              <br className="space-special" />y relaciones comerciales a largo
              plazo.
            </h2>
          </div>
        </div>
        <div className="collaborators-us">
          <h2>
            Los fabricantes que orgullosamente representamos en México son:
          </h2>
          <div className="collaborators-items">
            <div
              onClick={(e) => handleClick(e, "ARI-Armaturen", 1)}
              className="collaborator-item"
            >
              <img src={Armaturen} alt="Armaturen" />
            </div>
            <div
              onClick={(e) => handleClick(e, "Stream Flo", 2)}
              className="collaborator-item"
            >
              <img src={StreamFlo} alt="Stream Flo" />
            </div>
            <div
              onClick={(e) => handleClick(e, "OMC", 3)}
              className="collaborator-item"
            >
              <img src={Omc} alt="Omc Italy" />
            </div>
            <div
              onClick={(e) => handleClick(e, "4Matic", 4)}
              className="collaborator-item"
            >
              <img src={Formatic} alt="Formatic" />
            </div>
            <div
              onClick={(e) => handleClick(e, "LSM", 5)}
              className="collaborator-item"
            >
              <img src={Lsm} alt="Lsm" />
            </div>
          </div>
        </div>
      </div>
      <Modal onClose={onCloseModal} isOpen={open}>
        <a href={modalUrl}>
          <h1 className="custom-modal-about-title">{modalTitle}</h1>
        </a>
        <div>
          <ul>
            {modalItem
              ? modalItem.items.map((i) => (
                <li className="custom-modal-about-text" key={i.code}>
                  {i.name}
                </li>
              ))
              : null}
          </ul>
        </div>
        <span>
          <a className="custom-modal-about-link" href={modalUrl}>
            <strong>Visitar sitio</strong>
          </a>
        </span>
      </Modal>
    </div>
  );
}

export default About;
