import React, { useState } from "react";
import "./Home.scss";
import Modal from "../../components/modal/Modal";

// Images
import CompanySec from "../../assets/images/welcome.jpg";

// Logos
import Formatic from "../../assets/images/4Matic.png";
import Armaturen from "../../assets/images/ARMATUREN.jpg";
import StreamFlo from "../../assets/images/SF-logo.png";
import Lsm from "../../assets/images/LSM-logo.png";
import Omc from "../../assets/images/OMC-ITALY.png";

// Icons
import { ReactComponent as Calidad } from "../../assets/icons/calidad.svg";
import { ReactComponent as RespuestaRapida } from "../../assets/icons/respuestaRapida.svg";
import { ReactComponent as ServicioCampo } from "../../assets/icons/servicioEnCampo.svg";
import { ReactComponent as Tiempos } from "../../assets/icons/tiemposDeEntregaCortos.svg";
import { ReactComponent as Asesoria } from "../../assets/icons/asesoria.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";

const MARCAS_ITEMS = [
  {
    id: 1,
    items: [
      { code: "A1", name: "Válvulas de Control tipo Globo y Mariposa" },
      { code: "A2", name: "Válvulas de Corte Automáticas y Manuales" },
      { code: "A3", name: "Válvulas de Seguridad y Alivio" },
      { code: "A4", name: "Reguladores de Presión y Temperatura" },
      { code: "A5", name: "Trampas de Vapor y Condensados" },
      { code: "A6", name: "Sistemas de Ingeniería" },
    ],
    url: "https://www.ari-armaturen.us/",
  },
  {
    id: 2,
    items: [
      {
        code: "F1",
        name: "Válvulas de Bola montadas sobre Muñon Clases: 150 -2500#",
      },
      { code: "F2", name: "Válvulas de Bola Flotante" },
    ],
    url: "https://www.streamflo.com/en/",
  },
  {
    id: 3,
    items: [
      { code: "O1", name: "Válvulas de Control tipo Globo Clases: 150-2500#" },
      { code: "O2", name: "Válvulas de Control Sanitarias" },
      { code: "O3", name: "Reguladores Presión" },
      { code: "O4", name: "Posicionadores y Accesorios" },
      { code: "O5", name: "Instrumentación Neumática" },
    ],
    url: "https://www.omcamericas.com/",
  },
  {
    id: 4,
    items: [
      {
        code: "41",
        name:
          "Válvulas de Mariposa Concentricas, Alto Rendimiento y Triple Excentricidad",
      },
      { code: "42", name: "Válvulas de Bola de 2, 3 y 4 vías" },
      { code: "43", name: "Válvulas para manejo de Sistemas de agua potable" },
      {
        code: "44",
        name: "Accesorios -Posicionadores, Interruptores de Limite, Solenoides",
      },
    ],
    url: "https://www.4matic.us/",
  },
  {
    id: 5,
    items: [{ code: "L1", name: "Bombas Peristálticas" }],
    url: "https://lsm.dk/en/",
  },
];

function Home() {
  let [open, setOpen] = useState(false);
  let [modalTitle, changeTitle] = useState("");
  let [modalItem, changeItem] = useState(null);
  let [modalUrl, changeUrl] = useState("");

  const handleClick = (e, marca, id) => {
    e.preventDefault();
    changeTitle(marca);
    const actualMarca = MARCAS_ITEMS.find((m) => Number(m.id) === Number(id));
    changeItem(actualMarca);
    changeUrl(actualMarca.url);
    onOpenModal();
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="hero-container">
        <h1>
          ESPECIALISTAS
          <br />
          EN VÁLVULAS.
        </h1>
      </div>
      <div className="info-container">
        <div className="info-container-company">
          <div className="company-text">
            <h2>
              Somos una empresa cimentada <br />
              en más de 20 años de experiencia <br />
              en el campo de válvulas e <br />
              instrumentación.
            </h2>
          </div>
          <div className="company-image">
            <img src={CompanySec} alt="welcome" />
          </div>
        </div>
        <div className="services">
          <div className="services-title">
            <h2>Brindamos:</h2>
          </div>
          <div className="services-content">
            <div className="services-content-one">
              <div className="services-item">
                <Asesoria width="80" height="100" />
                <h3>Asesoría</h3>
              </div>
              <div className="services-item">
                <ServicioCampo width="80" height="100" />
                <h3>Servicio en campo</h3>
              </div>
              <div className="services-item">
                <Calidad width="80" height="100" />
                <h3>Calidad</h3>
              </div>
            </div>
            <div className="services-content-two">
              <div className="services-item">
                <Tiempos width="80" height="100" />
                <h3>Tiempos de entrega cortos</h3>
              </div>
              <div className="services-item">
                <RespuestaRapida width="80" height="100" />
                <h3>Respuesta rápida</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="collaborators">
          <h2>Marcas</h2>
          <div className="collaborators-items">
            <div
              onClick={(e) => handleClick(e, "ARI-Armaturen", 1)}
              className="collaborator-item"
            >
              <img src={Armaturen} alt="Armaturen" />
            </div>
            <div
              onClick={(e) => handleClick(e, "Stream Flo", 2)}
              className="collaborator-item"
            >
              <img src={StreamFlo} alt="Stream Flo" />
            </div>
            <div
              onClick={(e) => handleClick(e, "OMC", 3)}
              className="collaborator-item"
            >
              <img src={Omc} alt="Omc Italy" />
            </div>
            <div
              onClick={(e) => handleClick(e, "4Matic", 4)}
              className="collaborator-item"
            >
              <img src={Formatic} alt="Formatic" />
            </div>
            <div
              onClick={(e) => handleClick(e, "LSM", 5)}
              className="collaborator-item"
            >
              <img src={Lsm} alt="Lsm" />
            </div>
          </div>
        </div>
      </div>
      <section className="contact">
        <h2>Contacto</h2>
        <div className="contact-content">
          <div className="contact-help">
            <div className="contact-item">
              <span>
                <strong>¿Necesitas auyuda?</strong>
              </span>
            </div>
            <div className="contact-item">
              <div className="contact-text-container">
                <span>Llámanos a los números:</span>
              </div>

              <span>
                <a href="tel:+5559191284">
                  <strong>(55) 5919 - 1284 / (55) 5919 - 1338</strong>
                </a>
              </span>
            </div>
            <div className="contact-item">
              <div className="contact-text-container">
                <span>Envía un WhatsApp:</span>
              </div>
              <span>
                <a href="https://wa.me/5215545557030?text=Me%20gustaría%20contactar%20con%20ustedes.">
                  <strong>(55) 4555-7030</strong>
                </a>
              </span>
            </div>
            <div className="contact-item">
              Manda un
              <span>
                <a href="mailto:ventas@moreval.net?Subject=Quiero%20cotactar%20con%20un%20vendedor">
                  <strong> correo electrónico.</strong>
                </a>
              </span>
            </div>
          </div>
          <div className="line-vertical"></div>
          <div className="contact-office">
            <div className="contact-item">
              <div className="contact-text-container">
                <span>Visita nuestras oficinas </span>
              </div>
              <div className="contact-text-container">
                <span>
                  <a href="https://www.google.com.mx/maps/place/Calle+R%C3%ADo+Tiber+50,+Cuauht%C3%A9moc,+06500+Ciudad+de+M%C3%A9xico,+CDMX/@19.4311961,-99.1720503,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff4ad1f89407:0x163127bd557ccf74!8m2!3d19.4311961!4d-99.1698616">
                    <strong>Calle Rio Tiber 50</strong>
                  </a>
                </span>
              </div>
              <div className="contact-text-container">
                <span>
                  <a href="https://www.google.com.mx/maps/place/Calle+R%C3%ADo+Tiber+50,+Cuauht%C3%A9moc,+06500+Ciudad+de+M%C3%A9xico,+CDMX/@19.4311961,-99.1720503,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff4ad1f89407:0x163127bd557ccf74!8m2!3d19.4311961!4d-99.1698616">
                    <strong>Piso 5 Desp. C</strong>
                  </a>
                </span>
              </div>
              <div className="contact-text-container">
                <span>
                  <a href="https://www.google.com.mx/maps/place/Calle+R%C3%ADo+Tiber+50,+Cuauht%C3%A9moc,+06500+Ciudad+de+M%C3%A9xico,+CDMX/@19.4311961,-99.1720503,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff4ad1f89407:0x163127bd557ccf74!8m2!3d19.4311961!4d-99.1698616">
                    <strong>Cuauhtémoc CDMX</strong>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="line-vertical"></div>

          <div className="contact-follow">
            <div className="contact-follow-item">
              <span>SIGUENOS EN:</span>
              <a href="https://www.facebook.com/valvulasmoreval">
                <Facebook width="50" height="50" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Modal onClose={onCloseModal} isOpen={open}>
        <a href={modalUrl}>
          <h1 className="custom-modal-home-title">{modalTitle}</h1>
        </a>
        <div>
          <ul>
            {modalItem
              ? modalItem.items.map((i) => (
                <li className="custom-modal-home-text" key={i.code}>
                  {i.name}
                </li>
              ))
              : null}
          </ul>
        </div>
        <span>
          <a className="custom-modal-home-link" href={modalUrl}>
            <strong>Visitar sitio</strong>
          </a>
        </span>
      </Modal>
    </div>
  );
}

export default Home;
