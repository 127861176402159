import React, { useState } from "react";
import "./Contact.scss";
import axios from "axios";
import Modal from "../../components/modal/Modal";

// Icons
import { ReactComponent as FacebookBlue } from "../../assets/icons/facebook-blue.svg";
import { ReactComponent as WhatsBlue } from "../../assets/icons/whatsapp-blue.svg";

function Contact() {
  let [name, changeName] = useState("");
  let [email, changeEmail] = useState("");
  let [phone, changePhone] = useState("");
  let [message, changeMessage] = useState("");
  let [open, setOpen] = useState(false);
  let [modalMessage, changeModalMessage] = useState("");
  let [modalTitle, changeModalTitle] = useState("");
  const myRef = React.useRef();

  const handleClick = (e) => {
    e.preventDefault();
    if (!name) {
      changeModalTitle("Cuidado");
      changeModalMessage("Ayudanos agregando tu nombre, ¡Queremos conocerte!");
      onOpenModal();
      return;
    }
    if (!email) {
      changeModalTitle("Cuidado");
      changeModalMessage("Necesitamos tu dirección de correo para contactarte");
      onOpenModal();
      return;
    }
    if (name && email) {
      SendMail();
    }
  };

  const SendMail = () => {
    const body = {
      name: name,
      email: email,
      phone: phone,
      message: message,
      emailTo: "ventas@moreval.net",
      subject: "CONTACTO moreval.net | " + name
    };

    axios
      .post("https://us-central1-vd-send-emails.cloudfunctions.net/app/api/contact-us", body)
      .then(() => {
        changeModalTitle("Tu correo fue enviado");
        changeModalMessage("Pronto nos pondremos en contacto");
        onOpenModal();
        changeName("");
        changeEmail("");
        changePhone("");
        changeMessage("");
      })
      .catch(() => {
        changeModalTitle("Falla al enviar el correo");
        changeModalMessage(
          "Puedes contactarnos mediante los botones del costado derecho"
        );
        onOpenModal();
        changeName("");
        changeEmail("");
        changePhone("");
        changeMessage("");
      });
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="contact-hero">
        <div className="contact-hero-image">
          <h1>Contacto</h1>
          {/* <img src={ContacSec} alt="Contacto" /> */}
        </div>
      </div>
      <section className="contact-contact">
        <div className="contact-contact-sec1">
          <h2>Queremos escucharte</h2>
          <div ref={myRef} className="form">
            <input
              name="name"
              value={name}
              onChange={(e) => changeName(e.target.value)}
              placeholder="Nombre"
              type="text"
            />
            <div className="form-direction">
              <input
                value={email}
                placeholder="Dirección Correo Electrónico"
                type="email"
                onChange={(e) => changeEmail(e.target.value)}
              />
              <input
                value={phone}
                placeholder="Télefono"
                type="text"
                onChange={(e) => changePhone(e.target.value)}
              />
            </div>

            <textarea
              placeholder="Mensaje"
              name="message"
              value={message}
              id=""
              cols="30"
              rows="6"
              onChange={(e) => changeMessage(e.target.value)}
            ></textarea>

            <button onClick={handleClick} type="submit">
              Enviar
            </button>
          </div>
        </div>
        <div className="contact-contact-sec2">
          <div className="contact-icons">
            <a href="https://www.facebook.com/valvulasmoreval">
              <FacebookBlue className="blue-icon" width="50" height="50" />
            </a>
            <a href="https://wa.me/5215545557030?text=Me%20gustaría%20contactar%20con%20ustedes.">
              <WhatsBlue className="blue-icon" width="50" height="50" />
            </a>
          </div>
          <div className="contact-text-info">
            <span>Llámanos a los números :</span>
            <br />
            <span>
              <a href="tel:+5559191284">
                <strong>(55) 5919 - 1284</strong>
              </a>
            </span>
            <br />
            <span>
              <a href="tel:+5559191338">
                <strong>(55) 5919 - 1338</strong>
              </a>
            </span>
          </div>
          <div className="contact-text-info">
            <span>Visita nuestras oficinas: </span>
            <br />
            <span>
              <a href="https://www.google.com.mx/maps/place/Calle+R%C3%ADo+Tiber+50,+Cuauht%C3%A9moc,+06500+Ciudad+de+M%C3%A9xico,+CDMX/@19.4311961,-99.1720503,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff4ad1f89407:0x163127bd557ccf74!8m2!3d19.4311961!4d-99.1698616">
                <strong>Calle Rio Tiber 50</strong>
              </a>
            </span>
            <br />
            <span>
              <a href="https://www.google.com.mx/maps/place/Calle+R%C3%ADo+Tiber+50,+Cuauht%C3%A9moc,+06500+Ciudad+de+M%C3%A9xico,+CDMX/@19.4311961,-99.1720503,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff4ad1f89407:0x163127bd557ccf74!8m2!3d19.4311961!4d-99.1698616">
                <strong>Piso 5 Desp. C</strong>
              </a>
            </span>
            <br />
            <span>
              <a href="https://www.google.com.mx/maps/place/Calle+R%C3%ADo+Tiber+50,+Cuauht%C3%A9moc,+06500+Ciudad+de+M%C3%A9xico,+CDMX/@19.4311961,-99.1720503,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff4ad1f89407:0x163127bd557ccf74!8m2!3d19.4311961!4d-99.1698616">
                <strong>Cuauhtémoc CDMX</strong>
              </a>
            </span>
          </div>
          <div className="contact-text-info">
            <span>
              <a href="mailto:ventas@moreval.net?Subject=Quiero%20cotactar%20con%20un%20vendedor">
                <strong>ventas@moreval.net</strong>
              </a>
            </span>
          </div>
        </div>
      </section>
      <Modal onClose={onCloseModal} isOpen={open}>
        <h1 className="custom-modal-title">{modalTitle}</h1>
        <p className="custom-modal-text">{modalMessage}</p>
      </Modal>
    </div>
  );
}

export default Contact;
