import React from "react";
import ReactDOM from "react-dom";

// Styles
import "./Modal.scss";

function Modal(props) {
  if (!props.isOpen) {
    return null;
  } else {
    return ReactDOM.createPortal(
      <div className="Modal">
        <div className="Modal-container">
          <button onClick={props.onClose} className="Modal-close-button">
            X
          </button>

          {props.children}
        </div>
      </div>,
      document.getElementById("modal")
    );
  }
}

export default Modal;
