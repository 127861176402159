import React from "react";
import "./BurgerIcon.scss";

export default React.forwardRef(({ open, ...props }, ref) => (
  <div
    ref={ref}
    className={open ? "burger-menu open" : "burger-menu"}
    {...props}
  >
    <div className="bar1" key="b1" />
    <div className="bar2" key="b2" />
    <div className="bar3" key="b3" />
  </div>
));
