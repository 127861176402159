import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

// Images
import Moreval from "../../assets/images/moreval.png";
import Vainilla from "../../assets/images/Logo-vd.png";

function Footer() {
  return (
    <React.Fragment>
      <footer className="footer">
        <Link to="/"><img src={Moreval} alt="Moreval" /></Link>        
        <span>
          © 2020 Moreval S.A. de C.V. | Website by
          <a href="https://vainilladev.com/"> Vainilla Dev</a>
        </span>
        <a href="https://vainilladev.com/">
          <img src={Vainilla} alt="Vainilla Dev"/>
        </a>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
