import React from "react";

// Components
import Menu from "../menu/Menu";
import Footer from "../footer/Footer";

function Layout(props) {
  return (
    <React.Fragment>
      <Menu />
      {props.children}
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
