import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

// Views
import Home from "./views/home/Home";
import About from "./views/about-us/About";
import Contact from "./views/contact/Contact";
import Products from "./views/products/Products";

// Components
import Layout from "./components/layout/Layout";

function App() {
  return (
    <HashRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/nosotros" component={About} />
          <Route path="/productos" component={Products} />
          <Route exact path="/contacto" component={Contact} />
        </Switch>
      </Layout>
    </HashRouter>
  );
}

export default App;
