import React from "react";
import { NavLink } from "react-router-dom";
import "./BurgerMenu.scss";

export default ({ close }) => (
  <div className="menu">
    <ul>
      <li>
        <NavLink onClick={close} activeClassName="current" to="/">
          INICIO
        </NavLink>
      </li>
      <li>
        <NavLink onClick={close} activeClassName="current" to="/nosotros">
          NOSOTROS
        </NavLink>
      </li>
      <li>
        <NavLink onClick={close} activeClassName="current" to="/productos">
          PRODUCTOS
        </NavLink>
      </li>
      <li>
        <NavLink onClick={close} activeClassName="current" to="/contacto">
          CONTACTO
        </NavLink>
      </li>
    </ul>
  </div>
);
