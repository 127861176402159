import React, { Component } from "react";
import "./Products.scss";
import { Switch, Route, Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

// Components
import ProductsImages from "../../components/products-images/ProductsImages";

// Icons
import { ReactComponent as Arrow } from "../../assets/icons/enviar.svg";

// Images
// Válvulas de Control
import ValvulaC1 from "../../assets/images/vavulas-control/Valvulas-Control-01.jpg";
import ValvulaC2 from "../../assets/images/vavulas-control/Valvulas-Control-02.jpg";
// Válvulas de Corte
import ValvulaCor1 from "../../assets/images/valvulas-corte/valvula-corte-01.jpg";
import ValvulaCor2 from "../../assets/images/valvulas-corte/valvula-corte-02.png";
import ValvulaCor3 from "../../assets/images/valvulas-corte/valvula-corte-03.jpg";
// Reguladores
import Regulador01 from "../../assets/images/reguladores/Regulador-01.jpg";
import Regulador02 from "../../assets/images/reguladores/Regulador-02.jpg";
import Regulador03 from "../../assets/images/reguladores/Regulador-03.png";
// Válvulas de Seguridad y Alivio
import ValvulaSeg01 from "../../assets/images/valvulas-seguridad/valvula-seg-01.jpg";
import ValvulaSeg02 from "../../assets/images/valvulas-seguridad/valvula-seg-02.jpg";
// Trampas de Vapor y Condensados
import Trampa01 from "../../assets/images/trampas-vapor/trampas-01.png";
import Trampa02 from "../../assets/images/trampas-vapor/trampas-02.png";
// Sistemas de Ingeniería
import Sistema01 from "../../assets/images/sistemas/ARI-PREsys S.jpg";
import Sistema02 from "../../assets/images/sistemas/ARI-CORsys St.jpg";
// Posicionadores y Accesorios
import Posicionador01 from "../../assets/images/posicionadores/posicionador-01.png";
import Posicionador02 from "../../assets/images/posicionadores/posicionador-02.png";
// Instrumentación Neumática
import Instrumentacion01 from "../../assets/images/instrumentacion/instrumentacion-01.jpg";
import Instrumentacion02 from "../../assets/images/instrumentacion/instrumentacion-02.jpg";
import Instrumentacion03 from "../../assets/images/instrumentacion/instrumentacion-03.jpg";
// Bombas peristalticas
import Bomba01 from "../../assets/images/bombas/bomba-01.jpeg";

const PRODUCT_LIST = [
  {
    id: 1,
    title: "Válvulas de Control",
    subList: [
      {
        id: 1,
        subTitle: "Globo",
      },
      {
        id: 2,
        subTitle: "Mariposa",
      },
      {
        id: 3,
        subTitle: "Sanitarias",
      },
      {
        id: 4,
        subTitle: "Purga de caldera",
      },
    ],
  },
  {
    id: 2,
    title: "Válvulas de Corte",
    subList: [
      {
        id: 5,
        subTitle: "Mariposa",
      },
      {
        id: 6,
        subTitle: "Bola",
      },
      {
        id: 7,
        subTitle: "Globo y Retención",
      },
    ],
  },
  {
    id: 3,
    title: "Reguladores",
    subList: [
      {
        id: 8,
        subTitle: "Presión",
      },
      {
        id: 9,
        subTitle: "Temperatura",
      },
    ],
  },
  {
    id: 4,
    title: "Válvulas de Seguridad y Alivio",
    subList: [
      {
        id: 10,
        subTitle: "Boquilla completa ASME VIII / API 526",
      },
      {
        id: 11,
        subTitle: "Semi boquilla",
      },
    ],
  },
  {
    id: 5,
    title: "Trampas de Vapor y Condensados",
    subList: [
      {
        id: 12,
        subTitle: "Flotador",
      },
      {
        id: 13,
        subTitle: "Bimetálica",
      },
      {
        id: 14,
        subTitle: "Termostática",
      },
      {
        id: 15,
        subTitle: "Termodinámica",
      },
      {
        id: 16,
        subTitle: "Manifolds",
      },
      {
        id: 28,
        subTitle: "Sistemas de bombeo mecánicos",
      },
    ],
  },
  {
    id: 9,
    title: "Sistemas de Ingeniería",
    subList: [
      {
        id: 26,
        subTitle: "Sistemas reductores de presión",
      },
      {
        id: 27,
        subTitle: "Sistema de recuperación y retorno de condensados",
      },
    ],
  },
  {
    id: 6,
    title: "Posicionadores y accesorios",
    subList: [
      {
        id: 17,
        subTitle: "Posicionadores Analogicos",
      },
      {
        id: 18,
        subTitle: "Posicionadores Digitales Inteligentes",
      },
      {
        id: 19,
        subTitle: "Filtro Regulador",
      },
      {
        id: 20,
        subTitle: "Accesorios para instrumentacion",
      },
    ],
  },
  {
    id: 7,
    title: "Instrumentación Neumática",
    subList: [
      {
        id: 21,
        subTitle: "Convertidores I/P",
      },
      {
        id: 22,
        subTitle: "Transmisores",
      },
      {
        id: 23,
        subTitle: "Controladores",
      },
      {
        id: 24,
        subTitle: "Registradores",
      },
    ],
  },
  {
    id: 8,
    title: "Bombas peristalticas",
    subList: [
      {
        id: 25,
        subTitle: "Bombas peristalticas",
      },
    ],
  },
];

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
    };
  }

  putItemList = () =>
    PRODUCT_LIST.map((item, index) => (
      <li key={item.id}>
        <div className="list-title">
          <span>{item.title}</span>

          <Arrow
            onClick={() => this.showSubMenu(item.id)}
            cursor="pointer"
            width="16"
            height="16"
          />
        </div>
        {this.state.details.includes(item.id) ? (
          <div>
            <ul>{this.putSubItemList(item.subList)}</ul>
          </div>
        ) : null}
      </li>
    ));

  putSubItemList = (sublist) =>
    sublist.map((item) => (
      <li key={item.id}>
        <Fade left>
          <div className="sub-list">
            <Link to={`/productos/${item.id}`}>
              <span>{item.subTitle}</span>
            </Link>
          </div>
        </Fade>
      </li>
    ));

  showSubMenu = (id) => {
    if (this.state.details.includes(id)) {
      let newDetails = this.state.details.filter((detail) => detail !== id);

      this.setState({
        details: [newDetails],
      });
    } else {
      this.setState({
        details: this.state.details.length ? [id] : [id],
      });
    }
  };

  render() {
    return (
      <div>
        <section className="product-container">
          <div className="product-container-list">
            <div className="product-list">
              <ul>{this.putItemList()}</ul>
            </div>
          </div>
          <div className="product-container-preview">
            <Switch>
              <Route exact path="/productos">
                <div className="preview">
                  <h1>Categoría</h1>
                  <div className="preview-sec1">
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={ValvulaC1} alt="Válvula Control 01" />
                        <img src={ValvulaC2} alt="Válvula Control 02" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Válvulas de Control</h3>
                      </div>
                    </div>
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={ValvulaCor1} alt="Válvula Corte 01" />
                        <img src={ValvulaCor2} alt="Válvula Corte 02" />
                        <img src={ValvulaCor3} alt="Válvula Corte 03" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Válvulas de Corte</h3>
                      </div>
                    </div>
                    <div className="submenu-item">
                      <div className="submenu-item-images ">
                        <img src={Regulador01} alt="Regulador 01" />
                        <img src={Regulador02} alt="Regulador 02" />
                        <img src={Regulador03} alt="Regulador 03" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Reguladores</h3>
                      </div>
                    </div>
                  </div>
                  <div className="preview-sec1">
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={ValvulaSeg01} alt="Válvula Seguridad 01" />
                        <img src={ValvulaSeg02} alt="Válvula Seguridad 02" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Válvulas de Seguridad y Alivio</h3>
                      </div>
                    </div>
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={Trampa01} alt="Trampa 01" />
                        <img src={Trampa02} alt="Trampa 02" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Trampas de Vapor y Condensados</h3>
                      </div>
                    </div>
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={Sistema01} alt="Sistema 01" />
                        <img src={Sistema02} alt="Sistema 02" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Sistemas de Ingeniería</h3>
                      </div>
                    </div>
                  </div>
                  <div className="preview-sec1">
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={Posicionador01} alt="Posicionador 01" />
                        <img src={Posicionador02} alt="Posicionador 02" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Posicionadores y Accesorios</h3>
                      </div>
                    </div>
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={Instrumentacion01} alt="Instrumentación 01" />
                        <img src={Instrumentacion02} alt="Instrumentación 02" />
                        <img src={Instrumentacion03} alt="Instrumentación 03" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Instrumentación Neumática</h3>
                      </div>
                    </div>
                    <div className="submenu-item">
                      <div className="submenu-item-images">
                        <img src={Bomba01} alt="Bomba 01" />
                      </div>
                      <div className="submenu-item-title">
                        <h3>Bombas peristalticas</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/productos/:subId">
                <ProductsImages />
              </Route>
            </Switch>
          </div>
        </section>
      </div>
    );
  }
}

export default Product;
