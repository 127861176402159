import React from "react";
import "./ProductsImages.scss";
import { useParams } from "react-router-dom";

// Images
// Globo
import Globo01 from "../../assets/images/vavulas-control/globo-01.jpg";
import Globo02 from "../../assets/images/vavulas-control/ARI-STEVI Pro 422-462.jpg";
import Globo03 from "../../assets/images/vavulas-control/ARI-STEVI Pro 470-471.jpg";
import Globo04 from "../../assets/images/vavulas-control/ARI-STEVI Vario 448-449.jpg";
import Globo05 from "../../assets/images/vavulas-control/ARI-STEVI Smart 423-463.jpg";
import Globo06 from "../../assets/images/vavulas-control/ARI-STEVI Smart 425-426.jpg";
import Globo07 from "../../assets/images/vavulas-control/ARI-STEVI Smart 440-441.jpg";
import Globo08 from "../../assets/images/vavulas-control/ARI-STEVI Smart 450-451.jpg";
// Mariposa
import Mariposa01 from "../../assets/images/vavulas-control/mariposa-01.jpg";
import Mariposa02 from "../../assets/images/vavulas-control/mariposa-02.jpg";
import Mariposa03 from "../../assets/images/vavulas-control/mariposa-03.jpg";
import Mariposa04 from "../../assets/images/vavulas-control/mariposa-04.jpg";
// Sanitarias
import Sanitarias01 from "../../assets/images/vavulas-control/sanitaria-01.jpg";
import Sanitarias02 from "../../assets/images/vavulas-control/sanitaria-02.jpg";
import Sanitarias03 from "../../assets/images/vavulas-control/sanitaria-03.jpg";
// Purga de caldera
import Purga01 from "../../assets/images/vavulas-control/purga-01.jpg";
//Mariposa Corte
import MariposaCorte01 from "../../assets/images/valvulas-corte/mariposa-corte-01.png";
import MariposaCorte02 from "../../assets/images/valvulas-corte/mariposa-corte-02.png";
import MariposaCorte03 from "../../assets/images/valvulas-corte/mariposa-corte-03.png";
import MariposaCorte04 from "../../assets/images/valvulas-corte/mariposa-corte-04.png";
import MariposaCorte05 from "../../assets/images/valvulas-corte/mariposa-corte-05.jpg";
// Bola
import Bola01 from "../../assets/images/valvulas-corte/bola-01.jpg";
import Bola02 from "../../assets/images/valvulas-corte/bola-02.png";
import Bola03 from "../../assets/images/valvulas-corte/bola-03.jpg";
// Globo Compuerta Retención
import GloboCompuerta01 from "../../assets/images/valvulas-corte/globo-compuerta-01.jpg";
import GloboCompuerta05 from "../../assets/images/valvulas-corte/globo-compuerta-05.png";
import GloboCompuerta06 from "../../assets/images/valvulas-corte/globo-compuerta-06.png";
// Presión
import Presion01 from "../../assets/images/reguladores/presion-01.jpg";
import Presion02 from "../../assets/images/reguladores/presion-02.jpg";
import Presion03 from "../../assets/images/reguladores/presion-03.jpg";
// Temperatura
import Temperatura01 from "../../assets/images/reguladores/temperatura-01.jpg";
// Boquilla completa ASME VIII / API 526
import Boquilla01 from "../../assets/images/valvulas-seguridad/boquilla-completa-01.jpg";
import Boquilla02 from "../../assets/images/valvulas-seguridad/boquilla-completa-02.jpg";
import Boquilla03 from "../../assets/images/valvulas-seguridad/boquilla-completa-03.jpg";
// Semi boquilla
import Semiboquilla01 from "../../assets/images/valvulas-seguridad/semiboquilla-01.jpg";
import Semiboquilla02 from "../../assets/images/valvulas-seguridad/semiboquilla-02.jpg";
// Flotador
import Flotador01 from "../../assets/images/trampas-vapor/flotador-01.png";
// Bimetálica
import Bimetalica01 from "../../assets/images/trampas-vapor/bimetalica-01.png";
// Termostática
import Termostatica01 from "../../assets/images/trampas-vapor/termostatica-01.png";
// Termodinámica
import Termodinamica01 from "../../assets/images/trampas-vapor/termostatica-01.png";
// Manifolds
import Manifolds01 from "../../assets/images/trampas-vapor/manifolds-01.jpg";
// Posicionadores Analogicos
import Analogicos01 from "../../assets/images/posicionadores/analogicos-01.png";
import Analogicos02 from "../../assets/images/posicionadores/analogicos-02.png";
import Analogicos03 from "../../assets/images/posicionadores/analogicos-03.png";
// Posicionadores Digitales Inteligentes
import Digitales01 from "../../assets/images/posicionadores/digitales-01.png";
import Digitales02 from "../../assets/images/posicionadores/digitales-02.png";
import Digitales03 from "../../assets/images/posicionadores/digitales-03.png";
// Filtro Regulador
import FiltroRegulador from "../../assets/images/posicionadores/filtro-regulador.jpeg";
// import Manifolds01 from "../../assets/images/trampas-vapor/manifolds-01.jpg";
// Accesorios para instrumentacion
import Accesorios01 from "../../assets/images/posicionadores/accesorios-01.png";
// Convertidores
import Convertidor01 from "../../assets/images/instrumentacion/convertidor-01.jpg";
// Transmisores
import Transmisor01 from "../../assets/images/instrumentacion/transmisores-01.jpg";
// Controladores
import Controlador01 from "../../assets/images/instrumentacion/controlador-01.jpg";
// Registradores
import Registrador01 from "../../assets/images/instrumentacion/registradores-01.jpg";
// Bombas perisalticas
import Bombas01 from "../../assets/images/bombas/bombas-pe-01.png";
import Bombas02 from "../../assets/images/bombas/bombas-pe-02.jpg";
import Bombas03 from "../../assets/images/bombas/bombas-pe-03.jpeg";
import Bombas04 from "../../assets/images/bombas/bombas-pe-04.jpeg";
// Sistemas de Ingeniería
import Sistema01 from "../../assets/images/sistemas/ARI-PREsys S.jpg";
import Sistema02 from "../../assets/images/sistemas/ARI-CORsys St.jpg";
// Sistemas de bombeo mecánicos
import Sistema03 from "../../assets/images/sistemas-bombeo-mecanicos/CONLIFT.png";
import Sistema04 from "../../assets/images/sistemas-bombeo-mecanicos/CONA P.jpeg";


// Icons
const IMAGES = [
  {
    id: 1,
    name: "Globo",
    images: [
      {
        imageName: "Globo 01",
        image: Globo01,
      },
      {
        imageName: "Globo 02",
        image: Globo02,
      },
      {
        imageName: "Globo 03",
        image: Globo03,
      },
      {
        imageName: "Globo 04",
        image: Globo04,
      },
      {
        imageName: "Globo 05",
        image: Globo05,
      },
      {
        imageName: "Globo 06",
        image: Globo06,
      },
      {
        imageName: "Globo 07",
        image: Globo07,
      },
      {
        imageName: "Globo 08",
        image: Globo08,
      },
    ],
  },
  {
    id: 2,
    name: "Mariposa",
    images: [
      {
        imageName: "Mariposa 01",
        image: Mariposa01,
      },
      {
        imageName: "Mariposa 02",
        image: Mariposa02,
      },
      {
        imageName: "Mariposa 03",
        image: Mariposa03,
      },
      {
        imageName: "Mariposa 04",
        image: Mariposa04,
      },
    ],
  },
  {
    id: 3,
    name: "Sanitarias",
    images: [
      {
        imageName: "Sanitaria 01",
        image: Sanitarias01,
      },
      {
        imageName: "Sanitaria 02",
        image: Sanitarias02,
      },
      {
        imageName: "Sanitaria 03",
        image: Sanitarias03,
      },
    ],
  },
  {
    id: 4,
    name: "Purga de caldera",
    images: [
      {
        imageName: "Purga 01",
        image: Purga01,
      },
    ],
  },
  {
    id: 5,
    name: "Mariposa",
    images: [
      {
        imageName: "Mariposa corte 01",
        image: MariposaCorte01,
      },
      {
        imageName: "Mariposa corte 02",
        image: MariposaCorte02,
      },
      {
        imageName: "Mariposa corte 03",
        image: MariposaCorte03,
      },
      {
        imageName: "Mariposa corte 04",
        image: MariposaCorte04,
      },
      {
        imageName: "Mariposa corte 05",
        image: MariposaCorte05,
      },
    ],
  },
  {
    id: 6,
    name: "Bola",
    images: [
      {
        imageName: "Bola 01",
        image: Bola01,
      },
      {
        imageName: "Bola 02",
        image: Bola02,
      },
      {
        imageName: "Bola 03",
        image: Bola03,
      },
    ],
  },
  {
    id: 7,
    name: "Globo y Retención",
    images: [
      {
        imageName: "Globo Compuerta 01",
        image: GloboCompuerta01,
      },
      {
        imageName: "Globo Compuerta 05",
        image: GloboCompuerta05,
      },
      {
        imageName: "Globo Compuerta 06",
        image: GloboCompuerta06,
      },
    ],
  },
  {
    id: 8,
    name: "Presión",
    images: [
      {
        imageName: "Presion 01",
        image: Presion01,
      },
      {
        imageName: "Presion 02",
        image: Presion02,
      },
      {
        imageName: "Presion 03",
        image: Presion03,
      },
    ],
  },
  {
    id: 9,
    name: "Temperatura",
    images: [
      {
        imageName: "Temperatura 01",
        image: Temperatura01,
      },
    ],
  },
  {
    id: 10,
    name: "Boquilla completa ASME VIII / API 526",
    images: [
      {
        imageName: "Boquilla Completa 01",
        image: Boquilla01,
      },
      {
        imageName: "Boquilla Completa 02",
        image: Boquilla02,
      },
      {
        imageName: "Boquilla Completa 03",
        image: Boquilla03,
      },
    ],
  },
  {
    id: 11,
    name: "Semi boquilla",
    images: [
      {
        imageName: "Semi boquilla 01",
        image: Semiboquilla01,
      },
      {
        imageName: "Semi boquilla 02",
        image: Semiboquilla02,
      },
    ],
  },
  {
    id: 12,
    name: "Flotador",
    images: [
      {
        imageName: "Flotador 01",
        image: Flotador01,
      },
    ],
  },
  {
    id: 13,
    name: "Bimetálica",
    images: [
      {
        imageName: "Bimetalica 01",
        image: Bimetalica01,
      },
    ],
  },
  {
    id: 14,
    name: "Termostática",
    images: [
      {
        imageName: "Termostatica 01",
        image: Termostatica01,
      },
    ],
  },
  {
    id: 15,
    name: "Termodinámica",
    images: [
      {
        imageName: "Termodinamica 01",
        image: Termodinamica01,
      },
    ],
  },
  {
    id: 16,
    name: "Manifolds",
    images: [
      {
        imageName: "Manifolds 01",
        image: Manifolds01,
      },
    ],
  },
  {
    id: 17,
    name: "Posicionadores Analógicos",
    images: [
      {
        imageName: "Analogico 01",
        image: Analogicos01,
      },
      {
        imageName: "Analogico 02",
        image: Analogicos02,
      },
      {
        imageName: "Analogico 03",
        image: Analogicos03,
      },
    ],
  },
  {
    id: 18,
    name: "Posicionadores Digitales Inteligentes",
    images: [
      {
        imageName: "Digital 01",
        image: Digitales01,
      },
      {
        imageName: "Digital 02",
        image: Digitales02,
      },
      {
        imageName: "Digital 03",
        image: Digitales03,
      },
    ],
  },
  {
    id: 19,
    name: "Filtro Regulador",
    images: [
      {
        imageName: "Filtro Regulador",
        image: FiltroRegulador,
      },
    ],
  },
  {
    id: 20,
    name: "Accesorios para Instrumentación",
    images: [
      {
        imageName: "Instrumentacion 03",
        image: Accesorios01,
      },
    ],
  },
  {
    id: 21,
    name: "Convertidores I/P",
    images: [
      {
        imageName: "Convertidor 01",
        image: Convertidor01,
      },
    ],
  },
  {
    id: 22,
    name: "Transmisores",
    images: [
      {
        imageName: "Transmisor 01",
        image: Transmisor01,
      },
    ],
  },
  {
    id: 23,
    name: "Controladores",
    images: [
      {
        imageName: "Controlador 01",
        image: Controlador01,
      },
    ],
  },
  {
    id: 24,
    name: "Registradores",
    images: [
      {
        imageName: "Registrador 01",
        image: Registrador01,
      },
    ],
  },
  {
    id: 25,
    name: "Bombas perisalticas",
    images: [
      {
        imageName: "Bomba 01",
        image: Bombas01,
      },
      {
        imageName: "Bomba 02",
        image: Bombas02,
      },
      {
        imageName: "Bomba 03",
        image: Bombas03,
      },
      {
        imageName: "Bomba 04",
        image: Bombas04,
      },
    ],
  },
  {
    id: 26,
    name: "Sistemas reductores de presión",
    images: [
      {
        imageName: "Sistemas reductores de presión",
        image: Sistema01,
      },
    ],
  },
  {
    id: 27,
    name: "Sistema de recuperación y retorno de condensados",
    images: [
      {
        imageName: "Sistema de recuperación y retorno de condensados",
        image: Sistema02,
      },
    ],
  },
  {
    id: 28,
    name: "Sistemas de bombeo mecánicos",
    images: [
      {
        imageName: "CONA P",
        image: Sistema03,
      },
      {
        imageName: "CONLIFT",
        image: Sistema04,
      },
    ],
  },
];

function ProductsImages(props) {
  let { subId } = useParams();

  const item = IMAGES.find((imagen) => Number(imagen.id) === Number(subId));

  return item ? (
    <div className="content-full">
      <h1>{item.name}</h1>
      <div className="images-p-container">
        <div>
          {item.images.map((image) => (
            <img
              key={image.imageName}
              src={image.image}
              alt={image.imageName}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
}

export default ProductsImages;
