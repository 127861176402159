import React from "react";
import "./Menu.scss";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import BurgerIcon from "../burger-icon/BurgerIcon";
import BurgerMenu from "../burger-menu/BurgerMenu";

// Icons
import { ReactComponent as WhatsIcon } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";

// Images
import Logo from "../../assets/images/logo.png";

const contentStyle = {
  background: "rgba(255,255,255,0)",
  width: "80%",
  border: "none"
};

function Menu() {
  return (
    <React.Fragment>
      <header>
        <div className="navbar">
          <nav>
            <a href = "mailto:ventas@moreval.net?Subject=Quiero%20cotactar%20con%20un%20vendedor">
              <Mail width="24" height="24" className="icon" title="correo" /> 
            </a>
            <a href = "https://wa.me/5215545557030?text=Me%20gustaría%20contactar%20con%20ustedes.">
              <WhatsIcon
                width="24"
                height="24"
                className="icon"
                title="whatsapp"
              />
            </a>
            <a href = "tel:+5559191284">
              <Phone width="24" height="24" className="icon" title="telefono" />
            </a>
            <a href = "tel:+5559191284">
              <h4>(55) 5919-1284 / 1338</h4>
            </a>
          </nav>
        </div>
        <div className="second-navbar">
        <Link to="/"><img src={Logo} alt="Logo" /></Link>
          <nav>
            <Link to="/">INICIO</Link>
            <Link to="/nosotros">NOSOTROS</Link>
            <Link to="/productos">PRODUCTOS</Link>
            <Link to="/contacto">CONTACTO</Link>
          </nav>
        </div>
      </header>
      <Popup
        modal
        overlayStyle={{ background: "rgba(255,255,255,0.98" }}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={open => <BurgerIcon open={open} />}
      >
        {close => <BurgerMenu close={close} />}
      </Popup>
    </React.Fragment>
  );
}

export default Menu;
